import React from "react";
import "./RightPannel.scss";

const RightPannel = () => {
  return (
    <div className="right-pannel">
      
    </div>
  );
};

export default RightPannel;
